<template>
    <view-section-card title="내용">
        <v-card flat>
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <v-text-field v-model="form.name" label="이름" v-bind="{ ...attrs_input }" @input="emit" />
                    </v-col>
                    <v-col cols="6">
                        <v-select v-model="form._category" :items="categoryItems" label="카테고리" v-bind="{ ...attrs_input }" multiple clearable @input="emit" />
                    </v-col>
                    <v-col cols="2">
                        <v-checkbox v-model="all" class="mt-0" label="모두선택" hide-details @change="checkAll" />
                    </v-col>
                    <v-col cols="2">
                        <v-text-field v-model="form.index" label="순서" v-bind="{ ...attrs_input }" @input="emit" />
                    </v-col>
                    <v-col colse="5">
                        <v-text-field v-model="form.code" label="코드" v-bind="{ ...attrs_input }" @input="emit" />
                    </v-col>
                    <v-col cols="5">
                        <v-text-field v-model="form.desc" label="설명" v-bind="{ ...attrs_input }" @input="emit" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </view-section-card>
</template>

<script>
import { attrs_input, attrs_switch } from "@/assets/variables";
import { initForm } from "./filteringForm.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
export default {
    components: {
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: initForm },
    },
    data: () => ({
        attrs_input,
        attrs_switch,

        all: false,
        form: initForm(),
    }),
    computed: {
        categoryItems() {
            return this.$store.state.categories
                .flatMap(function flatMap(category) {
                    let categories = [category];
                    if (0 < category.children.length) {
                        categories.push(...category.children.flatMap(flatMap));
                    }
                    return categories;
                })
                .map(({ name, _id }) => ({ text: name, value: _id }));
        },
    },
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.form = initForm(this.value);
        },
        checkAll(value) {
            if (value) this.form._category = this.categoryItems.map(({ value }) => value);
            else this.form._category = [];

            this.emit();
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value: {
            handler(newVal) {
                this.sync();
            },
            deep: true,
        },
        "form._category"() {
            this.all = this.categoryItems.length === this.form._category.length;
        },
    },
};
</script>

<style>
</style>