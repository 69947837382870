<template>
    <v-dialog v-model="shows" width="840" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-0">
                <v-row>
                    <v-col cols="12">
                        <view-section-card title="필터링 타입">
                            <v-card flat>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <v-select v-model="form.type" :items="typeItems" label="필터링 타입" v-bind="{ ...attrs_input, loading }" />
                                        </v-col>
                                        <v-col v-if="!isFilterKey">
                                            <v-select v-model="form._key" :items="keyItems" label="필터링 이름" v-bind="{ ...attrs_input, loading }"/>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </view-section-card>
                    </v-col>
                    <v-col cols="12">
                        <filtering-key-props v-if="isFilterKey" v-model="form"/>
                        <filtering-value-props v-else v-model="form" v-bind="{ isFilterKey, keyCode }"/>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, attrs_switch } from "@/assets/variables";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import FilteringKeyProps from "./filteringKeyProps.vue";
import FilteringValueProps from "./filteringValueProps.vue";

export const initColorValue = (value = {}) => ({
    color: value?.color || null,
    _img: value?.img || null
})

export const initCollectionValue = (value = {}) => ({
    _category: value?._category || null
})

export const initShapeValue = (value = {}) => ({
    _img: value?._img || null
})

export const initForm = (form = {}) => ({
    ...form,
    type: form?.type || "key",
    name: form?.name || null,
    code: form?.code || null,
    desc: form?.desc || null,
});

const typeItems = [
    { text: "이름", value: "key" },
    { text: "값", value: "value" },
];

export default {
    components: {
        ViewSectionCard,
        FilteringKeyProps,
        FilteringValueProps
    },
    props: {
        _id: { type: String, default: null },
    },
    data: () => ({
        form: initForm(),
        categories: [],
        typeItems,
        keyItems: [],
        keyCode: "",

        shows: false,
        loading: false,

        attrs_input,
        attrs_switch,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "필터링";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
        isFilterKey() {
            return this.form.type === 'key';
        }
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
        "form.type"() {
            if(this.isCreate){
                this.form = initForm({ type: this.form.type });
            }
        },
        "form._key"() {
            this.setKeyCode();
            this.setFilteringValue();
        }
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) {
                    this.form = initForm();
                } else {
                    let { _id } = this;
                    this.form = (await api.console.shop.filterings.get({ _id })).filtering;
                    // this.$emit("input", { ...this.form });
                }
                this.search();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async search() {
            try {
                let keys = (await api.console.shop.filterings.gets({ params: { type: "key" } }))?.filterings || [];
                this.keyItems = keys.map((key) => ({ text: `${key?.name}(${key?.code})`, value: key?._id, code: key?.code }));
                this.setKeyCode();
            } catch (error) {
                this.$handleError(error);
            }
        },
        validates() {
            let { name } = this.form;
            if (!name) throw new Error("이름을 등록하셔야 합니다");
        },
        async save() {
            if (this.loading) return;

            this.loading = true;
            try {
                this.validates();

                let { post, put } = api.console.shop.filterings;
                let filtering = (await (this.isCreate ? post : put)(this.form))?.filtering;

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", filtering);
                this.shows = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        setKeyCode(){
            this.keyCode = (this.keyItems.filter(({ value }) => value === this.form._key))[0]?.code || "";
        },
        setFilteringValue() {
            switch(this.keyCode) {
                case "Collections":
                    this.form.value = initCollectionValue(this.form.value);
                    break;
                case "Color":
                    this.form.value = initColorValue(this.form.value);
                    break;
                case "Shape":
                    this.form.value = initShapeValue(this.form.value);
                    break;
                default:
                    this.form.value = null;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
        line-height: 1.3;
    }
}
</style>
