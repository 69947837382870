import { render, staticRenderFns } from "./filteringForm.vue?vue&type=template&id=225a8370&scoped=true&"
import script from "./filteringForm.vue?vue&type=script&lang=js&"
export * from "./filteringForm.vue?vue&type=script&lang=js&"
import style0 from "./filteringForm.vue?vue&type=style&index=0&id=225a8370&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "225a8370",
  null
  
)

export default component.exports