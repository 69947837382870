var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', {
    attrs: {
      "title": "내용"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "이름"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    attrs: {
      "colse": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "코드"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "설명"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.desc,
      callback: function ($$v) {
        _vm.$set(_vm.form, "desc", $$v);
      },
      expression: "form.desc"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "순서"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.index,
      callback: function ($$v) {
        _vm.$set(_vm.form, "index", $$v);
      },
      expression: "form.index"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _vm.keyCode === 'Collections' ? [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.categoryItems,
      "label": "카테고리",
      "clearable": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.value._category,
      callback: function ($$v) {
        _vm.$set(_vm.form.value, "_category", $$v);
      },
      expression: "form.value._category"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input), false))], 1)] : _vm._e(), _vm.keyCode === 'Color' ? [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    staticClass: "fill-height",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('span', [_vm._v("색상")]), _c('v-card', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "flat": ""
    }
  }, [_c('v-color-picker', {
    attrs: {
      "hide-mode-switch": "",
      "mode": "hexa"
    },
    on: {
      "update:color": _vm.changeColor
    },
    model: {
      value: _vm.form.value.color,
      callback: function ($$v) {
        _vm.$set(_vm.form.value, "color", $$v);
      },
      expression: "form.value.color"
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    staticClass: "fill-height",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('span', {
    staticClass: "d-inline-block white caption pa-1",
    staticStyle: {
      "position": "absolute",
      "top": "0",
      "left": "8px",
      "transform": "translateY(-50%)"
    }
  }, [_vm._v("파일")]), _c('image-input', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "width": "100%",
      "flat": ""
    },
    model: {
      value: _vm.form.value._img,
      callback: function ($$v) {
        _vm.$set(_vm.form.value, "_img", $$v);
      },
      expression: "form.value._img"
    }
  })], 1)], 1)], 1)] : _vm._e()], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }